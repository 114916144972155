<template scoped>
  <div>
    <el-form-item
      :rules="rules.systolic"
      label="Systolic Blood Record"
      prop="save.bloodPressure.systolic"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.systolic" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.systolicValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.diastolic"
      label="Diastolic Blood Record"
      prop="save.bloodPressure.diastolic"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.diastolic" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.diastolicValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.pulse"
      label="Pluse Record"
      prop="save.bloodPressure.pulse"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.pulse" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.pulseValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.year"
      label="Year"
      prop="save.bloodPressure.year"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.year" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.yearValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.month"
      label="Month"
      prop="save.bloodPressure.month"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.month" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.monthValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.day"
      label="Day"
      prop="save.bloodPressure.day"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.day" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.dayValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.hour"
      label="Hour"
      prop="save.bloodPressure.hour"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.hour" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.hourValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.minute"
      label="Minute"
      prop="save.bloodPressure.minute"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.minute" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.minuteValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.second"
      label="Second"
      prop="save.bloodPressure.second"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.save.bloodPressure.second" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.save.bloodPressure.secondValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    validator_systolic: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the systolic should not be empty"));
      } else {
        callback();
      }
    },
    validator_diastolic: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the diastolic should not be empty"));
      } else {
        callback();
      }
    },
    validator_pulse: function (rule, value, callback) {
      callback();
    },
    validator_year: function (rule, value, callback) {
      callback();
    },
    validator_month: function (rule, value, callback) {
      callback();
    },
    validator_day: function (rule, value, callback) {
      callback();
    },
    validator_hour: function (rule, value, callback) {
      callback();
    },
    validator_minute: function (rule, value, callback) {
      callback();
    },
    validator_second: function (rule, value, callback) {
      callback();
    },
  },
  data: function () {
    return {
      rules: {
        systolic: [{ validator: this.validator_systolic, trigger: "blur" }],
        diastolic: [{ validator: this.validator_diastolic, trigger: "blur" }],
        pulse: [{ validator: this.validator_pulse, trigger: "blur" }],
        year: [{ validator: this.validator_year, trigger: "blur" }],
        month: [{ validator: this.validator_month, trigger: "blur" }],
        day: [{ validator: this.validator_day, trigger: "blur" }],
        hour: [{ validator: this.validator_hour, trigger: "blur" }],
        minute: [{ validator: this.validator_minute, trigger: "blur" }],
        second: [{ validator: this.validator_second, trigger: "blur" }],
      },
    };
  },
};
</script>    